<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container">
            <div class="white-container__title">
                <h5>Обращения граждан</h5>
                <router-link to="appeal_forms" class="button_link" v-show="!showLogin"> Подать обращение </router-link>
<!--              <a href="https://oprflk.dev.devfbit.ru" class="button_link" v-show="!showLogin"> Подать обращение </a>-->
            </div>
            <tricolor-line class="tricolor_line" v-show="!showLogin"/>
            <filter-appeal :showBlockSelect="showLogin"/>
            <div class="block__filter" v-show="showLogin">
              <p>За день <span></span></p>
              <p>По дате добавления <span></span></p>
              <p>Снача ранние <span></span></p>
            </div>
            <div class="main-contain">
                <div class="contain" :class="{'active_filter_type': getListView == 'btnList'}">
                    <appeal-card v-for="(item, key) in getAppeal" :key="`link${key}`" :card="item" :listView="getListView"
                    class="containet_block"/>
                </div>
                <div class="map" v-if="getListView == 'btnList'">
                    <map-form />
                    <router-link to="/" class="button_link map__position_button" v-show="!showLogin"> Смотреть на карте </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import FilterAppeal from '@/components/appeal/FilterAppeal'
import AppealCard from '@/components/appeal/AppealCard'
import MapForm from '@/components/appeal/MapForm'

export default {
  name: 'Appeal',
  components: {
    BreadCrumb,
    TricolorLine,
    FilterAppeal,
    AppealCard,
    MapForm
  },
  data () {
    return {
      // showLogin: false,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Обращения граждан'
        }
      ]
    }
  },
  mounted () {
    // this.$store.dispatch('getSpecificAppeal')
    // console.log(this.$store.getters.getSpecificAppeal)

    // localStorage.getItem('token') ? this.showLogin = true : this.showLogin = false
    if (this.$store.getters.getAppeal.length === 0) {
      this.dispatchDataAppeal()
    }
    this.$store.dispatch('setView', 'btnBlock')
  },
  computed: {
    showLogin () {
      // console.log((localStorage.getItem('token')) ? true : false)
      // return localStorage.getItem('token') ? true : false
      if (localStorage.getItem('token')) {
        return true
      } else {
        return false
      }
    },
    getAppeal () {
      // console.log('test', this.$store.getters.getAppeal)
      return this.$store.getters.getAppeal
    },
    getListView () {
      return this.$store.getters.getListView
    }
  },
  methods: {
    async dispatchDataAppeal () {
      await this.$store.dispatch('getAppeal')
    }
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    // width: auto;
    // margin: 0 5rem;
    padding: 2.5rem 2.94rem;
    &__title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.81rem;
    }
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
}
.tricolor_line{
    margin-bottom: 0.75rem;
}
.main-contain{
  display: flex;
}
.contain{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.06rem;
    .containet_block{
        margin: 0 1.06rem 1.87rem 1.06rem;
        height: 18.9375rem;
        width: calc(33.333% - 2.125rem);
    }
}
.map{
    height: 49.94rem;
    flex: auto;
    margin-left: 2.19rem;
    position: relative;
    &__position_button{
      position: absolute;
      left: 6.69rem;
      bottom: 1.88rem;
      z-index: 999;
    }
}

.button_link {
    font-weight: bold;
    font-size: .88rem;
    line-height: 1.25rem;
    text-align: center;
    color: #FFFFFF;
    background: #EB494E;
    border-radius: .19rem;
    padding: .5rem 2.125rem .44rem 2.125rem;
}
.active_filter_type{
  flex-direction: column;
  width: 55.31rem;
  margin: 0;
  .containet_block{
    width: 100%;
    height: 13.94rem;
    margin: 0;
    margin-bottom: 1.56rem;
  }
}
.block__filter{
  display: flex;
  align-items: center;
  margin-bottom: 2.37rem;
  padding-bottom: .94rem;
  border-bottom: .063rem solid #D5D6D9;
  p{
    font-size: .81rem;
    line-height: 1.25rem;
    color: #1F3245;
    margin-right: 1.88rem;
    span{
      margin-left: .38rem;
      width: 0.53rem;
      height: 0.36rem;
      padding: 0 0.3rem;
      background: url('../../assets/icons/arrow_black.svg') center center no-repeat;
    }
  }
}

@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
  .contain .containet_block {
    width: 45%;
  }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .contain .containet_block {
      width: 100%;
    }
}

</style>
