<template>
  <l-map
    ref="appealMap"
    :zoom="geoData.zoom"
    :center="geoData.center"
    :options="geoData.mapOptions"
    style="flex: 0 0 auto; height: 100%; position: relative"
    :key="'map'+keyNum"
  >
    <l-tile-layer
      :url="geoData.url"
      :attribution="geoData.attribution"
    />
    <l-control-zoom :position="'bottomright'" />
      <l-geo-json :geojson="geoData.geojson" />
  </l-map>
</template>

<script>
import { latLng } from 'leaflet'
// import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LGeoJson } from 'vue2-leaflet'
import { LMap, LTileLayer, LGeoJson, LControlZoom } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
export default {
  name: 'MainMap',
  components: {
    LMap,
    LTileLayer,
    // LMarker,
    // LPopup,
    // LTooltip,
    LGeoJson,
    LControlZoom
  },
  props: {
    keyNum: {
      type: Number,
      define: false
    }
  },
  mounted () {
    this.geoData.geojson = []
  //   setTimeout(() => {
  //     this.$refs.myMap.mapObject.invalidateSize()
  //   }, 0)
  },
  data () {
    return {
      geoData: {
        zoom: 12,
        center: latLng(55.753215, 37.622504),
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        withPopup: latLng(47.41322, -1.219482),
        withTooltip: latLng(47.41422, -1.250482),
        currentZoom: 11.5,
        currentCenter: latLng(47.41322, -1.219482),
        showParagraph: false,
        mapOptions: {
          zoomSnap: 0.5,
          zoomDelta: 0.5,
          zoomControl: false
        },
        showMap: true,
        geojson: null,
        fillColor: '#e4ce7f',
        enableTooltip: false,
        control: {
          position: 'bottomleft'
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
