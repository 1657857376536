<template>
    <router-link tag="div" :to="{ path: `appeal/${card.id}` }"  v-if="listView === 'btnBlock'" class="appeal">
        <div class="appeal__header">
            <div class="appeal__type">
                <figure :style="`background: url('/assets/icons/hcs.svg') center / cover`"></figure>
                <!-- <figure :style="`background: url('${require('/assets/icons/' + card.icon)}') center / cover`"></figure> -->
                <p> {{card.topic.title}} </p>
            </div>
            <p> №{{card.number}} </p>
        </div>
        <p v-trim="2" class="appeal__title">{{ card.title }}</p>
        <div v-trim="4" class="appeal__subtitle" v-if="!card.photo">{{ card.body }}</div>
        <!-- <div v-trim="4" class="appeal__subtitle" v-if="!card.photo && card.photo.length==0">{{ card.body }}</div> -->
        <div v-else class="appeal__subtitle_photo">
            <div :style="`background: url('${'/assets/temp/' + card.photo[0]}') center / cover`">
                <span>{{card.photo.length}}</span>
            </div>
            <p v-trim="4">{{card.body}}</p>
        </div>
        <div class="appeal__location">
            <p v-trim="1"> Россия, {{ card.address.district}}, {{card.address.region}}, {{card.address.city}}</p>
            <span> {{ findingDate(card.created_date) }}</span>
        </div>
    </router-link>
    <!--  -->
    <router-link tag="div" :to="{ path: `appeal/${card.id}` }" v-else-if="listView === 'btnList'" class="appeal_list">
        <div class="appeal__header">
            <div class="appeal__type">
                <figure :style="`background: url('/assets/icons/small_icon/hcs_small.svg') center / contain; background-repeat: no-repeat;  `"></figure>
                <!-- <figure :style="`background: url('${require('/assets/icons/small_icon/' + card.small_icon)}') center / contain; background-repeat: no-repeat;  `"></figure> -->
                <p> {{card.topic.title}} </p>
            </div>
            <p class="appeal_list__number"> №{{card.number}} </p>
            <span> {{ findingDate(card.created_date) }}</span>
        </div>
        <p v-trim="1" class="appeal_list__title" v-if="!card.photo">{{ card.title }}</p>
        <div v-trim="3" class="appeal_list__subtitle" v-if="!card.photo">{{ card.body }}</div>
        <!-- <p v-trim="1" class="appeal_list__title" v-if="card.photo.length==0">{{ card.title }}</p>
        <div v-trim="3" class="appeal_list__subtitle" v-if="card.photo.length==0">{{ card.body }}</div> -->
        <div v-else class="appeal__subtitle_photo">
            <div :style="`background: url('${'/assets/temp/' + card.photo[0]}') center / cover`">
                <span>{{card.photo.length}}</span>
            </div>
            <div>
                <p v-trim="1" class="appeal__title">{{ card.title }}</p>
                <p v-trim="3">{{card.body}}</p>
            </div>
        </div>
        <div class="appeal__location">
            <p v-trim="1"> Россия, {{ card.address.district}}, {{card.address.region}}, {{card.address.city}}</p>
        </div>
    </router-link>
</template>
<script>
export default {
  name: 'AppealCard',
  props: {
    card: {
      type: Object
    },
    listView: {
      type: String,
      default: 'btnBlock'
    }
  },
  methods: {
    findingDate (date) {
      if (new Date(date).toDateString() === new Date().toDateString()) {
        return `Сегодня в ${new Date(date).toTimeString().slice(0, 5)}`
      } else if (new Date(date).toDateString() === new Date(Date.now() - 86400000).toDateString()) {
        return `Вчера в ${new Date(date).toTimeString().slice(0, 5)}`
      } else {
        return (new Date(date).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }).slice(0, -3))
      }
    }
  }
}
</script>

<style scoped lang="scss">
.appeal {
    padding: 1.875rem;
    width: 26.56rem;
    height: 19.38rem;
    background: #ffffff;
    box-shadow: 0px .94rem 1.56rem rgba(189, 205, 221, 0.35);
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    cursor: pointer;
    // margin-bottom: 2.5rem;

    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 1.06rem;

        font-size: .75rem;
        line-height: 1.125rem;
        color: rgba(23, 23, 23, 0.8);
    }

    &__type {
        display: flex;
        align-items: center;
        figure{
            width: 2.8125rem;
            height: 2.8125rem;
            margin-right: .75rem;
        }
        p {  color: #246CB7;  }
    }
    &__title {
        font-weight: 600;
        font-size: .875rem;
        line-height: 1.38rem;
        color: #1F3245;

        margin-bottom: 0.38rem;
    }
    &__subtitle{
        font-size: .875rem;
        line-height: 1.38rem;
        color: #5E686C;
    }
    &__subtitle_photo{
        width: 100%;
        display: flex;
        justify-content: space-between;
        div:nth-child(1){
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 8.75rem;
            height: 5.63rem;
            border-radius: .19rem;
            padding: 0.31rem;
            span{
                font-weight: 600;
                font-size: .75rem;
                line-height: 1.375rem;
                color: #EEF4FA;
            }
            span:before{
                content: '';
                padding: 0 .5rem;
                background: url('../../assets/icons/camera.svg') center center no-repeat;
                background-size: contain;
                margin-right: 0.31rem;
            }
        }
        p{
            margin-left: 1.063rem;
            flex: 1;
            font-size: .88rem;
            line-height: 1.38rem;
            color: #5E686C;
        }
    }
    &__location{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: auto;
        padding-top: .625rem;
        border-top: .06rem solid #EEF4FA;

        font-size: .75rem;
        line-height: 1.125rem;
        color: rgba(23, 23, 23, 0.8);
        p {
            color: #246CB7;
            width: 15.7rem;
        }
        p:before{
            content: ' ';
            width: .75rem;
            height: 1rem;
            padding: .004rem .375rem;
            background: url('../../assets/icons/location.svg') center center no-repeat;
            background-size: contain;
            margin-right: .44rem;
        }
    }
}
.appeal_list{
    padding: 1.56rem 1.13rem 1.56rem 1.56rem;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px .94rem 1.56rem rgba(189, 205, 221, 0.35);
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;

    .appeal__header{
        justify-content: flex-start;
        margin-bottom: 1.4rem;
    }
    .appeal__type{
        figure{
            width: 1.125rem;
            height: 1.125rem;
        }
        span{
            font-size: .75rem;
            line-height: 1.125rem;
            color: rgba(23, 23, 23, 0.8);
        }
    }
    &__number{
        font-weight: 600;
        font-size: .81rem;
        line-height: 1.25rem;
        padding: 0 1.25rem;
        margin: 0 1.25rem 0 1.56rem;
        border-left: .06rem solid #1F3245;
        border-right: .06rem solid #1F3245;
    }
    &__title {
        font-weight: 600;
        font-size: .875rem;
        line-height: 1.38rem;
        color: #1F3245;

        margin-bottom: 0.38rem;
        -webkit-line-clamp: 1 !important;
    }
    &__subtitle{
        font-size: .875rem;
        line-height: 1.38rem;
        color: #5E686C;
        -webkit-line-clamp: 3 !important;
    }
    .appeal__subtitle_photo{
        p{
            width: 42.81rem;
        }
    }
    .appeal__location{
        border: 0;
        p{
            width: 22rem;
        }
    }
}

@media screen and (max-width: 420px){
}
</style>
